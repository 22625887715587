<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      empty-message="No se encontraron clientes"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'two-line': true }"
      :loading="loadingData"
      :showing-filter.sync="showingDialogs.filter"
      :load="loadItems"
      :showing-sorter.sync="showingDialogs.sorter"
      :reload-dirty-items="reloadDirtyItems"
      :toolbar-options="toolbarOptions"
      :page-loaded="pageLoaded"
      pk-name="idcliente"
      @click-item="clickListItem"
      @click-toolbar-option="clickToolbarOption"
      @open-filter="openFilter"
    >
      <template
        #listItem="slotProps"
      >
        <cliente-list-item
          :item="slotProps.item"
        />
      </template>
    </b10-list>
    <b10-map-markers
      :markers="mapMarkers"
      title="Mapa clientes"
      :showing.sync="showingDialogs.map"
      icon-fieldname="icon"
      @bounds-changed="mapBoundsChanged"
      @click-marker="clickMapMarker"
    />
    <b10-map-marker-info
      :title="mapMarkerInfo.title"
      :subtitle="mapMarkerInfo.subtitle"
      :lat="mapMarkerInfo.lat"
      :lng="mapMarkerInfo.lng"
      :showing.sync="showingDialogs.mapMarkerInfo"
    >
      <template slot="cardExtraButtons">
        <v-btn
          color="info"
          dark
          @click="clickVerCliente(mapMarkerInfo.idcliente)"
        >
          <v-icon left>
            {{ $vuetify.icons.values.cliente }}
          </v-icon> Ver cliente
        </v-btn>
      </template>
    </b10-map-marker-info>
  </b10-base>
</template>

<script>
import { listPageMixin } from '@/mixins/listPageMixin'
import Data from './ClienteListData'
import ClienteListItem from './components/ClienteListItem'
import { get } from 'vuex-pathify'
import _ from '@/utils/lodash'
import { markerIcons } from '@/utils/maps'

const pageStoreName = 'pagesClienteList'

export default {
  components: {
    ClienteListItem,
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      pageStoreName,
      toolbarOptions: {
        map: {
          title: 'Ver en el mapa',
          visible: true,
          icon: 'map',
        },
      },
      showingDialogs: {
        mapMarkerInfo: false,
        map: false,
      },
      mapMarkerInfo: {
        title: '',
        subtitle: '',
        lat: null,
        lng: null,
      },
      mapMarkers: [],
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Clientes'
      this.pageLoaded = true
    },
    async loadItems () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectCliente(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage
        )
        await this.loadItemsBase(dataset, metadata)
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        const [dataset] = await Data.selectClienteRows(this, dirty.modified)
        await this.reloadItemsBase(dataset, dirty, 'idcliente')
      } finally {
        this.loadingData = false
      }
    },
    goToView (idcliente) {
      this.rememberState = true
      this.rememberScroll = true
      this.$appRouter.push({
        name: 'clientes__cliente-view',
        params: {
          idcliente: idcliente,
        }
      })
    },
    clickListItem (item) {
      this.goToView(item.data.idcliente)
    },
    clickToolbarOption (option) {
      if (option === this.toolbarOptions.map) {
        this.showingDialogs.map = true
      }
    },
    async openFilter () {
      // en evento openFilter y no en propiedad initFilter para no cargar
      // datos de los select hasta que no se abre el dialog del filtro
      if (this.filter.grupoCliente.items.length === 0) {
        const resp = await Data.selectFilterLookups(this)
        await this.setStoreProperty('filter@grupoCliente.items', resp.data.selectGrupoCliente.result.dataset)
      }
    },
    clickMapMarker (markerData) {
      this.mapMarkerInfo.title = this.$online.cliente.title(markerData)
      this.mapMarkerInfo.subtitle = this.$online.cliente.subtitle(markerData)
      this.mapMarkerInfo.lat = markerData.latitud
      this.mapMarkerInfo.lng = markerData.longitud
      this.mapMarkerInfo.idcliente = markerData.idcliente
      this.showingDialogs.mapMarkerInfo = !this.showingDialogs.mapMarkerInfo
    },
    async mapBoundsChanged (bounds) {
      let [markers] = await Data.selectCliente(
        this,
        this.filter,
        this.search,
        this.sorter,
        0,
        bounds,
        0
      )
      markers = _.map(markers, (marker) => {
        marker.icon = markerIcons.cliente
        return marker
      })
      this.mapMarkers = markers
    },
    clickVerCliente(idcliente) {
      this.showingDialogs.map = !this.showingDialogs.map
      this.showingDialogs.mapMarkerInfo = !this.showingDialogs.mapMarkerInfo
      this.goToView(idcliente)
    }
  },
}
</script>
