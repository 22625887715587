<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          :color="formattedItem.avatarColor"
        >
          <span class="white--text font-weight-bold subtitle-1">{{ formattedItem.avatar }}</span>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        class="text-wrap"
      >
        {{ formattedItem.title }}
      </v-list-item-title>
      <v-list-item-subtitle
        class="text-wrap"
      >
        {{ formattedItem.subtitle }}
        <div>
          <v-chip
            v-if="formattedItem.estado === CLIENTE.estados.bloqueado"
            label
            dark
            :color="CLIENTE.colores.bloqueado"
            small
            class="mr-1"
          >
            <span>
              {{ CLIENTE.descripcionEstado.bloqueado }}
            </span>
          </v-chip>
        </div>
      </v-list-item-subtitle>
    </v-list-item-content>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { CLIENTE } from '@/utils/consts'
import { stringToHslColor } from '@/utils/ui'
import { calculateMd5 } from '@/utils/crypto'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      CLIENTE
    }
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = this.$online.cliente.title(item)
      item.subtitle = this.$online.cliente.subtitle(item)
      item.avatar = this.$options.filters.initials(item.nombre)
      item.avatarColor = stringToHslColor(calculateMd5(item.idcliente.toString()))
      return item
    }
  }
}
</script>
